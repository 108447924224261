import { render, staticRenderFns } from "./SelectionSize.vue?vue&type=template&id=de765792"
import script from "./SelectionSize.vue?vue&type=script&lang=js"
export * from "./SelectionSize.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports