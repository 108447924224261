<template>
	<v-list-tile v-if="selectionSize">
		<v-list-tile-content>
			<v-list-tile-title>{{ $t('documents.selection_size') }}</v-list-tile-title>
			<v-list-tile-sub-title>
				{{ selectionSize }}
			</v-list-tile-sub-title>
		</v-list-tile-content>
	</v-list-tile>
</template>

<script>
import DocumentsManagerModuleGuard from '@/mixins/ModulesGuards/Documents/DocumentsManagerModuleGuard'

export default {
	name: 'SelectionSize',
	mixins: [DocumentsManagerModuleGuard],
	props: {
		value: {
			type: Object,
			required: false,
			default: null
		}
	},
	computed: {
		model: {
			get: function () {
				return this.value
			},
			set: function (value) {
				this.$emit('input', value)
			}
		},
		selectionSize: function () {
			return this.model && this.model.latestVersion?.size > 0 ? this.$humanReadableSize(this.model.latestVersion?.size) : null
		}
	},
	methods: {}
}
</script>
